.table-responsive {
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.table thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
    border-bottom: 2px solid #dee2e6;
}

.table tbody tr:hover {
    background-color: rgba(0, 123, 255, 0.05) !important;
}

@media (max-width: 768px) {
    .table {
        font-size: 0.9rem;
    }
    
    .dropdown-toggle {
        padding: 0.25rem 0.5rem;
        font-size: 0.875rem;
    }
}

/* Ensure modal content is scrollable on mobile */
.modal-body {
    max-height: 70vh;
    overflow-y: auto;
} 