.employees-container {
  padding: 20px;
  width: 100%;
  height: calc(100vh - 100px); /* Subtract header space */
  display: flex;
  flex-direction: column;
}

.employees-title {
  margin-bottom: 1.5rem;
  font-size: 2rem;
}

.employees-actions {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin-bottom: 2rem;
  flex-wrap: wrap;
}

.add-employee-btn {
  min-width: 150px;
}

.search-input {
  flex: 1;
  min-width: 250px;
  max-width: 500px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  padding: 2rem;
}

.table-responsive {
  margin-top: 1rem;
  overflow: auto;
  flex: 1;
  -webkit-overflow-scrolling: touch;
  position: relative;
  height: 100%;
  min-height: 200px;
}

.table-responsive table {
  margin-bottom: 0;
}

.table-responsive thead th {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1;
  border-top: none;
}

/* Make table more compact on mobile */
@media (max-width: 768px) {
  .employees-container {
    padding: 10px;
    height: calc(100vh - 80px); /* Slightly less padding on mobile */
  }

  .employees-title {
    font-size: 1.5rem;
  }

  .employees-actions {
    flex-direction: column;
    gap: 10px;
  }

  .search-input {
    width: 100%;
    max-width: 100%;
  }

  .add-employee-btn {
    width: 100%;
  }

  /* Adjust table for mobile */
  .table-responsive table {
    font-size: 0.9rem;
  }

  .table-responsive td,
  .table-responsive th {
    padding: 0.5rem;
    white-space: nowrap;
  }

  /* Make dropdown menu full width on mobile */
  .dropdown-menu {
    width: 100%;
  }
}

/* Modal responsiveness */
@media (max-width: 576px) {
  .modal-dialog {
    margin: 0.5rem;
  }
  
  .modal-body {
    padding: 1rem;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
  }
  
  .form-control {
    font-size: 0.9rem;
  }
} 