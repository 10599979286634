body, html {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

.app {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.background-video {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 100vw;
  min-height: 100vh;
  width: auto;
  height: auto;
  object-fit: cover;
  z-index: -1;
}

.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.login-box {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 400px;
  width: 100%;
  text-align: center;
  z-index: 1;
}

.logo-container {
  margin-bottom: 20px;
}

.logo {
  width: 100px;
  height: auto;
  animation: shrink-enlarge 2s infinite;
}

@keyframes shrink-enlarge {
  0% {
      transform: scale(1);
  }
  50% {
      transform: scale(0.9);
  }
  100% {
      transform: scale(1);
  }
}

.login-title {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333333;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.input-group {
  margin-bottom: 15px;
  text-align: left;
}

.input-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555555;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border: 1px solid #cccccc;
  border-radius: 5px;
  font-size: 16px;
}

.login-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background-color: #0056b3;
}

.error {
  color: red;
  margin-bottom: 15px;
}

.logout-spinner-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f0f0; /* Adjust as needed */
}

.logout-text {
  margin-bottom: 20px;
  font-size: 24px;
  color: #333;
  animation: fadeInOut 1.5s infinite;
}

@keyframes fadeInOut {
  0%, 100% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
}

/* Mobile Responsiveness */
@media screen and (max-width: 768px) {
  .login-box {
    margin: 20px;
    padding: 20px;
    max-width: 100%;
  }

  .login-title {
    font-size: 20px;
  }

  .logo {
    width: 80px;
  }

  .input-group input {
    font-size: 14px;
  }

  .login-button {
    font-size: 14px;
  }

  .background-video {
    width: 100vw;
    height: 100vh;
    object-position: center;
  }
}

@media screen and (max-height: 600px) {
  .login-box {
    padding: 15px;
  }

  .logo {
    width: 60px;
  }

  .login-title {
    margin-bottom: 10px;
  }

  .input-group {
    margin-bottom: 10px;
  }
}